import React, { useState, useRef, useMemo } from 'react'
import type { CMSSectionRichContentCarousel } from '@/types/sanity'

import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/scss'
import 'swiper/scss/pagination'
import 'swiper/css/free-mode'
import 'swiper/scss/navigation'
import 'swiper/css/thumbs'
import './SectionRichContentCarousel.scss'
import Section from './Section'

import config from 'tailwind.config'

import Link from '@/components/Link/Link'
import ResponsiveImage from '@components/Shared/ResponsiveImage'
import Video from '@/components/Shared/Video'
import useMediaQuery from '@/hooks/useMediaQuery'
import clsx from 'clsx'

type SectionRichContentCarouselProps = {
  content: CMSSectionRichContentCarousel
}

const SectionRichContentCarousel: React.FC<SectionRichContentCarouselProps> = ({
  content,
}) => {
  const { _id, eyebrow, header, infiniteLoop, cards, theme } = content

  const [_currentIndex, setCurrentIndex] = useState(0)

  const swiperRef = useRef<any>(null)

  const maxSlidesPerView = useMediaQuery(
    `(min-width: ${config.theme.screens.md})`,
  )
    ? 4
    : 1

  const isMobileScreen = useMediaQuery(
    `(max-width: ${config.theme.screens.md})`,
  )

  const slideCards = useMemo(() => {
    if (cards.length > maxSlidesPerView) {
      return cards
    }

    // Repeat cards to fill the carousel
    const repeatCount = Math.ceil((maxSlidesPerView + 1) / cards.length)
    return Array(repeatCount).fill(cards).flat()
  }, [cards, maxSlidesPerView])

  const handleNext = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slideNext()
    }
  }

  const carouselHeaderClassNames = clsx(
    'carousel-header flex justify-between pb-24',
    isMobileScreen ? 'items-end' : 'items-center',
  )

  return (
    <Section id={_id} theme={theme} size="sm" hasWrapper={false}>
      <div className="section-rich-content-carousel">
        {(eyebrow || header || infiniteLoop.desktop || infiniteLoop.mobile) && (
          <div className={carouselHeaderClassNames}>
            <div className="text-left">
              {eyebrow && <p className="eyebrow type-label">{eyebrow}</p>}
              {header && <h2 className="type-h4">{header}</h2>}
            </div>
            {((infiniteLoop.desktop && !isMobileScreen) ||
              (infiniteLoop.mobile && isMobileScreen)) && (
              <button
                className="button button--transparentArrow"
                type="button"
                onClick={handleNext}
              ></button>
            )}
          </div>
        )}

        <div className="carousel-container">
          <Swiper
            ref={swiperRef}
            slidesPerView="auto"
            spaceBetween={16}
            breakpoints={{
              768: {
                spaceBetween: 32,
              },
            }}
            loop={isMobileScreen ? infiniteLoop.mobile : infiniteLoop.desktop}
            touchEventsTarget="container"
            onSlideChange={(swiper) => setCurrentIndex(swiper.activeIndex)}
          >
            {(infiniteLoop.desktop ? slideCards : cards).map((card, index) => (
              <SwiperSlide key={index}>
                <div className={`carousel-card ${card.id}`}>
                  <div className={`card-body theme-${card.theme}`}>
                    {card.cardType === 'richTextCard' && (
                      <div className="card-rich-text">
                        <div>
                          {card.richTextCardEyebrow && (
                            <p className="type-label">
                              {card.richTextCardEyebrow}
                            </p>
                          )}
                          {card.richTextCardHeading && (
                            <h4
                              className={`type-${card.richTextCardHeadingType}`}
                            >
                              {card.richTextCardHeading}
                            </h4>
                          )}
                          {card.richTextCardDescription && (
                            <p className="type-body">
                              {card.richTextCardDescription}
                            </p>
                          )}
                        </div>

                        <div>
                          {card.richTextCardMedia && (
                            <div
                              className={`card-media ${
                                card.richTextCardCTA ? 'mb-16' : ''
                              }`}
                            >
                              {card.richTextCardMedia._type ===
                                'component.responsiveImage' && (
                                <ResponsiveImage
                                  document={card.richTextCardMedia}
                                  overrides={{
                                    desktopAspectRatio: '5/4',
                                    mobileAspectRatio: '5/4',
                                  }}
                                />
                              )}
                              {card.richTextCardMedia._type ===
                                'component.video' && (
                                <Video
                                  videoUrl={
                                    card.richTextCardMedia.video.secureUrl
                                  }
                                  videoType={card.richTextCardMedia.videoType}
                                  loopVideo={
                                    card.richTextCardMedia.background?.loopVideo
                                  }
                                  dialogueThumbnail={
                                    card.video.dialogue?.thumbnail
                                  }
                                  includeControls={
                                    card.video.dialogue?.includeControls
                                  }
                                  backupImage={
                                    card.richTextCardMedia.backupImage
                                  }
                                  buttonType="primary"
                                  classes="rich-text-video"
                                />
                              )}
                            </div>
                          )}
                          {card.richTextCardCTA &&
                            (card.richTextCardCTA.type === 'underlined' ? (
                              <Link
                                link={card.richTextCardCTA}
                                classes={[
                                  'underline',
                                  'type-label',
                                  'hover-underline',
                                ]}
                              >
                                {card.richTextCardCTA.text}
                              </Link>
                            ) : (
                              <button
                                className={`rich-text-cta button button--${card.richTextCardCTA.type}`}
                              >
                                {card.richTextCardCTA.text}
                              </button>
                            ))}
                        </div>
                      </div>
                    )}

                    {card.cardType === 'image' && card.image && (
                      <ResponsiveImage document={card.image} />
                    )}

                    {card.cardType === 'video' && card.video && (
                      <div className="card-video">
                        <Video
                          videoUrl={card.video.video.secureUrl}
                          videoType={card.video.videoType}
                          loopVideo={card.video.background?.loopVideo}
                          dialogueThumbnail={card.video.dialogue?.thumbnail}
                          includeControls={card.video.dialogue?.includeControls}
                          backupImage={card.video.backupImage}
                          buttonType="primary"
                        />
                      </div>
                    )}
                  </div>
                  {(card.eyebrow || card.body || card.link.text) && (
                    <div className="pt-24">
                      {card.eyebrow && (
                        <p className="type-label">{card.eyebrow}</p>
                      )}
                      {card.body && (
                        <p
                          className={`type-body ${card.eyebrow ? 'pt-8' : ''}`}
                        >
                          {card.body}
                        </p>
                      )}
                      {card.link && (
                        <Link
                          link={card.link}
                          classes={[
                            'underline',
                            'type-label',
                            'hover-underline',
                            'pt-8',
                          ]}
                        >
                          {card.link.text}
                        </Link>
                      )}
                    </div>
                  )}
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </Section>
  )
}

export default SectionRichContentCarousel
